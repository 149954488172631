// rollover
var preLoadImg = new Object();
 
function initRollOvers(){
  $("img.imgover,input.imgover").each(function(){
    var imgSrc = this.src;
    var sep = imgSrc.lastIndexOf('.');
    var onSrc = imgSrc.substr(0, sep) + '_o' + imgSrc.substr(sep, 4);
    preLoadImg[imgSrc] = new Image();
    preLoadImg[imgSrc].src = onSrc;
    $(this).hover(
      function() { this.src = onSrc; },
      function() { this.src = imgSrc; }
    );
  });
}
$(function(){
  initRollOvers();
});


// smoothScroll
jQuery.easing.quart = function (x, t, b, c, d) {
  return -c * ((t=t/d-1)*t*t*t - 1) + b;
};

jQuery(document).ready(function(){
  jQuery('a[href*="#"]').click(function() {    
    var targetId = jQuery(this.hash);
    console.log(targetId);
    
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      let target = jQuery(this.hash);
      target = target.length && target || jQuery('[name=' + this.hash.slice(1) +']');
    	let headerH = $('#header').innerHeight();
    	let adjust = 20;

      // ターゲット先へスクロール移動
      if (target.length) {
        var targetOffset = target.offset().top - headerH - adjust;
        jQuery('html,body').animate({ scrollTop: targetOffset }, 1200, 'swing');

        // ターゲット先がスライドパネルの場合はオープン
        if (target.next(".js-slideBox").find(".js-slideBtn").hasClass('is-active')) {
        } else {
          target.next(".js-slideBox").find(".js-slideBtn").addClass('is-active');
          target.next(".js-slideBox").find(".js-slideBtn").next(".js-slidePanel").slideToggle(300);
        }

        return false;
      }
    }
  });
});



// 他ページからのsmoothScroll
$(function(){
	//現在のページURLのハッシュ部分を取得
	let hash = location.hash;

	//ハッシュ部分がある場合の条件分岐
	if (hash) {
  	let headerH = $('#header').innerHeight();
  	
		$("html, body").stop().scrollTop(0);
		//処理を遅らせる
		setTimeout(function(){
			let target = $(hash),
			position = target.offset().top - headerH;
			
			$("html, body").animate({scrollTop:position}, 1200, "swing");
		}, "1");
	}
});


// slide btn
$(function(){
  $(".js-slideBtn").each(function(index,element) {
    $(element).click(function() {
      $(this).toggleClass('is-active');
      $(element).next(".js-slidePanel").slideToggle(300);
    });
  });

  // ハッシュがあるときはオープン
  if (window.location.hash.length>0) {
    if ($(window.location.hash).next(".js-slideBox").find(".js-slideBtn").hasClass('is-active')) {
    } else {
      $(window.location.hash).next(".js-slideBox").find(".js-slideBtn").toggleClass('is-active');
      $(window.location.hash).next(".js-slideBox").find(".js-slideBtn").next(".js-slidePanel").slideToggle(300);
    }
  }
});


// current
$(function() {
  var gNavItem = $("#gnav .js-gnavItem");

  var local_path_arr = $(location).attr('pathname').split('/');
  var local_dir = local_path_arr[1];

  gNavItem.each(function(){
    var href_path = $(this).attr("href");
    var href_path_arr = href_path.split('/');
    var href_dir = href_path_arr[1];
    
    if(href_dir == local_dir){
      $(this).addClass('is-current');
    } else {
      $(this).removeClass('is-current');
    }
  });

});


// header scroll settings
$(window).on('load scroll resize', function(){
  var headerPosTop = 30;  //ヘッダーの初期位置
  
  var winW = $(window).width();
  var winscrW = window.innerWidth;  /* スクロールバーを含む幅 */
  var hd = $('#header');
  var contentTop = $('#hero');
  var gnav = $('#gnavfix');
  var logo = $('#js-hdlogo');
  var gnavBtns = $('#ganvBtn');
  var scroll = $(window).scrollTop();

  if ( scroll == 0 && winscrW <= 799 ) {
    hd.removeClass('js-isscroll');
    hd.addClass('js-noscroll');
  } else if( scroll < headerPosTop && winscrW > 799 ) {
    hd.removeClass('js-isscroll');
    hd.addClass('js-noscroll');
  } else if ( winscrW > 799 ) {
    hd.removeClass('js-noscroll');
    hd.addClass('js-isscroll');
  } else {
    hd.removeClass('js-noscroll');
    hd.addClass('js-isscroll');
  }
});


// バーガーメニュー開閉
$(function(){
  $("#js-drawerOpen").click(function() {
    if ($("#js-drawerOpen").hasClass('is-open')) {
      $("#js-drawerOpen").removeClass('is-open');
      $("#drawer").removeClass('is-open');
      $("#gnav").removeClass('is-open');
      $("#js-drawerOpen").addClass('is-close');
      $("#drawer").addClass('is-close');
      $("#gnav").addClass('is-close');
    } else {
      $("#js-drawerOpen").removeClass('is-close');
      $("#drawer").removeClass('is-close');
      $("#gnav").removeClass('is-close');
      $("#js-drawerOpen").addClass('is-open');
      $("#drawer").addClass('is-open');
      $("#gnav").addClass('is-open');
    }
  });
});


// footer padding
/*
$(window).on('load scroll resize', function(){
  var footer = $('footer');
  var fixnavH = $('#fixBtmNav').outerHeight(true);
  footer.css({'padding-bottom' : fixnavH });
});
*/


//fadein
$(window).scroll(function (){
  $('.js-fadeIn').each(function(){
    var elemPos = $(this).offset().top;
    var scroll = $(window).scrollTop();
    var windowHeight = $(window).height();
    if (scroll > elemPos - windowHeight - 30){
        $(this).addClass('js-scrollIn');
    }
  });
});


// 電話番号リンク
if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)) {
  $(function() {
    $('.js-tellink').each(function() {
      var str = $(this).html();

      if ($(this).children().is('img')) {
        $(this).html($('<a>').attr('href', 'tel:' + $(this).children().attr('alt').replace(/[^0-9]/g, '')).append(str + '</a>')); //対象文字列内から数字だけ取り出してhrefにセット
      } else {
        $(this).html($('<a>').attr('href', 'tel:' + $(this).text().replace(/[^0-9]/g, '')).append(str + '</a>')); //対象文字列内から数字だけ取り出してhrefにセット
      }
    });
  });
}
